import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classnames from "classnames"

import { handelize } from "../utils/utils"
import { filterValidNameFields } from "../utils/data"

const ArtistsGrid = () => {
  const { airtableArtists } = useStaticQuery(graphql`
    {
      airtableArtists: allAirtable(
        filter: {
          table: { eq: "Artists" }
          data: { TNLG_Artist_: { eq: true } }
        }
        sort: { fields: data___Last_Name }
      ) {
        edges {
          node {
            data {
              Name
              Artist_Type
              Featured_Art {
                data {
                  Name
                  Images {
                    localFiles {
                      childImageSharp {
                        fluid(maxWidth: 500) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const artists = filterValidNameFields(airtableArtists).filter(
    e =>
      e.node.data.Featured_Art &&
      e.node.data.Featured_Art[0].data.Images &&
      e.node.data.Featured_Art[0].data.Images.localFiles
  )

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index
  }

  var allArtistTypes = artists
    .map(({ node }) => node.data.Artist_Type)
    .filter(type => !!type)
  var uniqueArtistTypes = allArtistTypes.filter(onlyUnique)

  const [artistsFilter, filterByType] = useState(null)
  return (
    <div className="max-w-6xl mx-auto w-full">
      <h2 className="text-center mb-4">Artists</h2>
      <div className="hidden md:block mb-8">
        <ul className="text-center flex items-center border-t border-b py-3 border-green justify-between">
          {uniqueArtistTypes.map(type => (
            <li key={type} className="px-2">
              <button
                onClick={() => filterByType(type)}
                key={type}
                className={classnames("text-left lg:text-xl", {
                  "font-bold underline disabled": type === artistsFilter,
                })}
              >
                {type}
              </button>
            </li>
          ))}
        </ul>
        <div className="text-center mt-3">
          {!!artistsFilter && (
            <button
              onClick={() => filterByType(null)}
              className="underline hover:no-underline leading-none italic text-left"
            >
              View All Artists
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-wrap flex-grow">
        {artists.map(({ node }) => {
          const { Featured_Art, Name, Artist_Type } = node.data

          const artistTile = (
            <Link
              to={`/${handelize(Name)}`}
              className="no-underline block text-center w-1/2 md:w-1/3 px-3 pb-6"
              key={Name}
            >
              <Img
                fluid={{
                  ...Featured_Art[0].data.Images.localFiles[0].childImageSharp
                    .fluid,
                  aspectRatio: 1,
                }}
                alt={Featured_Art[0].data.Name}
                className="w-full mb-3"
              />
              <h4 className="text-xl">{Name}</h4>
              <h5 className="text-base italic">{Artist_Type}</h5>
            </Link>
          )

          if (!!artistsFilter) {
            return Artist_Type === artistsFilter ? artistTile : <></>
          }

          return artistTile
        })}
      </div>
    </div>
  )
}

export default ArtistsGrid
