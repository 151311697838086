import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import RichText from "./richtext"

const Hero = () => {
  const { prismicHome } = useStaticQuery(graphql`
    {
      prismicHome {
        data {
          hero_subheadline
          hero_text
          hero_images {
            hero_image {
              fluid(maxWidth: 1024) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
          hero_text1 {
            html
          }
        }
      }
    }
  `)
  const {
    hero_subheadline,
    hero_text,
    hero_text1,
    hero_images,
  } = prismicHome.data

  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnDotsHover: true,
    speed: 600,
  }

  return (
    <>
      <div className="text-center pt-16 pb-6 md:pb-10 px-4 max-w-4xl mx-auto">
        <h1 className="mb-4 text-4xl md:text-5xl">{hero_text}</h1>
        <p className="md:text-2xl leading-tight">{hero_subheadline}</p>
      </div>
      {hero_text1 && hero_images.length && (
        <div className="flex flex-col md:flex-row max-w-6xl mx-auto px-4 items-start">
          <Slider {...sliderSettings} className="w-full md:w-1/2 mb-10">
            {hero_images.map(({ hero_image }) => (
              <Img fluid={{ ...hero_image.fluid, aspectRatio: 1 }} />
            ))}
          </Slider>
          <RichText html={hero_text1.html} className="md:w-1/2 md:pl-6 mb-6" />
        </div>
      )}
    </>
  )
}

export default Hero
