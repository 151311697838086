import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { dateInFuture, formatDate, formatHours } from "../utils/data"

const HEADLINE = "Upcoming Events"

const VIEW_ALL_LINK_TEXT = "View Calendar"

const UpcomingEvents = () => {
  const { allPrismicEvent } = useStaticQuery(graphql`
    {
      allPrismicEvent(sort: { fields: data___date }, limit: 10) {
        edges {
          node {
            uid
            data {
              name {
                text
              }
              date
              event_type
              location
              image {
                fluid(maxWidth: 500) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
              description {
                html
              }
            }
          }
        }
      }
    }
  `)
  const events = allPrismicEvent.edges
  const futureEvents = events.filter(event =>
    dateInFuture(event.node.data.date)
  )
  return (
    <div className="bg-white">
      <div className="max-w-6xl mx-auto w-full text-center py-10">
        <h3 className="mb-8">{HEADLINE}</h3>
        <div className="flex flex-wrap justify-center mb-10">
          {futureEvents.slice(0, 2).map(({ node }) => {
            const { date, event_type, name, image, location } = node.data

            return (
              <Link
                to={`/${node.uid}/`}
                className="block max-w-4xl px-4 text-left mb-10 no-underline flex flex-col md:flex-row w-full"
                key={name.text}
              >
                {image && (
                  <Img
                    fluid={{
                      ...image.fluid,
                    }}
                    className="w-full lg:flex-1"
                  />
                )}
                <div className="py-4 md:pl-6 lg:flex-1">
                  {event_type && <span className="text-sm">{event_type}</span>}
                  <h4 className="py-4">{name.text}</h4>
                  {location && <p>{location}</p>}
                  <p>{formatDate(date)}</p>
                  <p>{formatHours(date)}</p>
                </div>
              </Link>
            )
          })}
        </div>
        <Link to={`/events/`}>{VIEW_ALL_LINK_TEXT}</Link>
      </div>
    </div>
  )
}

export default UpcomingEvents
