import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import UpcomingEvents from "../components/upcoming-events"
import Exhibitions from "../components/exhibitions"
import ArtistsGrid from "../components/artists-grid"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <ArtistsGrid />
    <Exhibitions />
    <UpcomingEvents />
  </Layout>
)

export default IndexPage
